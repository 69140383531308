var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "list",
    {
      attrs: {
        list: _vm.checkSheetItems,
        "header-list": _vm.checkSheetHeaderList,
        "is-possible-hover-list": _vm.isPossibleHoverList,
        "is-draggable": _vm.isDraggable,
      },
      on: { changedList: _vm.onChangedList },
    },
    [
      _vm._l(_vm.checkSheetItems, function (checkSheetItem, index) {
        return _c(
          "li",
          { key: index, attrs: { slot: "list-body" }, slot: "list-body" },
          [
            _c("span", [
              _vm.isEditMode
                ? _c("button", { staticClass: "drag-handle" })
                : _vm._e(),
              _vm._v(" " + _vm._s(index + 1) + " "),
            ]),
            _c(
              "span",
              [
                _vm.isEditMode
                  ? _c("drop-down-box", {
                      attrs: {
                        id:
                          "check-cycle-" +
                          (checkSheetItem.id || checkSheetItem.uuid),
                        value: checkSheetItem.chkCycle,
                        disabled: !!checkSheetItem.id,
                        placeholder: "점검 주기",
                        optionItemsData: Object.keys(
                          _vm.checkSheetCycleOptionNames
                        ),
                        optionNames: _vm.checkSheetCycleOptionNames,
                      },
                      on: {
                        selectedOption: function ($event) {
                          var i = arguments.length,
                            argsArray = Array(i)
                          while (i--) argsArray[i] = arguments[i]
                          return _vm.onSelectedOption.apply(
                            void 0,
                            [checkSheetItem].concat(argsArray)
                          )
                        },
                      },
                    })
                  : [
                      _vm._v(
                        _vm._s(
                          _vm.checkSheetCycleOptionNames[
                            checkSheetItem.chkCycle
                          ]
                        )
                      ),
                    ],
              ],
              2
            ),
            _c(
              "span",
              [
                _vm.isEditMode
                  ? _c("input-box", {
                      attrs: {
                        type: "text",
                        id:
                          "check-name-" +
                          (checkSheetItem.id || checkSheetItem.uuid),
                        maxlength: 10,
                        placeholder: "10자 이내 입력",
                        autocomplete: "off",
                        "is-flex": true,
                      },
                      model: {
                        value: checkSheetItem.name,
                        callback: function ($$v) {
                          _vm.$set(checkSheetItem, "name", $$v)
                        },
                        expression: "checkSheetItem.name",
                      },
                    })
                  : [_vm._v(_vm._s(checkSheetItem.name))],
              ],
              2
            ),
            _c(
              "span",
              { staticClass: "white-space-pre-line justify-start" },
              [
                _vm.isEditMode
                  ? _c("textarea-box", {
                      attrs: {
                        type: "text",
                        id:
                          "check-method-" +
                          (checkSheetItem.id || checkSheetItem.uuid),
                        maxlength: 100,
                        placeholder: "상세 점검방법 입력",
                        autocomplete: "off",
                        "is-flex": true,
                        "is-resizable": true,
                      },
                      model: {
                        value: checkSheetItem.chkMethodDesc,
                        callback: function ($$v) {
                          _vm.$set(checkSheetItem, "chkMethodDesc", $$v)
                        },
                        expression: "checkSheetItem.chkMethodDesc",
                      },
                    })
                  : [_vm._v(_vm._s(checkSheetItem.chkMethodDesc))],
              ],
              2
            ),
            _c(
              "span",
              [
                checkSheetItem.rscFiles.length > 0
                  ? _c("photo", {
                      attrs: {
                        imgSrc: checkSheetItem.rscFiles[0].rscUrl,
                        isShowDeleteButton: _vm.isEditMode,
                      },
                      on: {
                        delete: function ($event) {
                          return _vm.onDeletePhoto(checkSheetItem)
                        },
                      },
                    })
                  : _vm.isEditMode
                  ? _c(
                      "file-basic",
                      {
                        staticClass: "text-button",
                        attrs: {
                          accept: ".jpg, .png",
                          id:
                            "check-file-" +
                            (checkSheetItem.id || checkSheetItem.uuid),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onSelectFile($event, checkSheetItem)
                          },
                        },
                      },
                      [_vm._v(" 추가 ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.isEditMode
              ? _c("span", [
                  _c(
                    "button",
                    {
                      staticClass: "text-button text-button-gray",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("copy", checkSheetItem)
                        },
                      },
                    },
                    [_vm._v("복제")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "text-button text-button-gray",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("delete", checkSheetItem)
                        },
                      },
                    },
                    [_vm._v("삭제")]
                  ),
                ])
              : _vm._e(),
          ]
        )
      }),
      !_vm.isEditMode
        ? _c(
            "p",
            {
              attrs: { slot: "no-list-description" },
              slot: "no-list-description",
            },
            [
              _c("b", [_vm._v("등록된 체크시트 항목이 없습니다.")]),
              _c("br"),
              _vm._v(" 등록버튼을 클릭하여 체크시트 항목을 추가 하세요. "),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }