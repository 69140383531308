<template>
  <list
    :list="checkSheetItems"
    :header-list="checkSheetHeaderList"
    :is-possible-hover-list="isPossibleHoverList"
    :is-draggable="isDraggable"
    @changedList="onChangedList"
  >
    <li slot="list-body" v-for="(checkSheetItem, index) in checkSheetItems" :key="index">
      <span>
        <button v-if="isEditMode" class="drag-handle" />
        {{ index + 1 }}
      </span>
      <span>
        <drop-down-box
          v-if="isEditMode"
          :id="`check-cycle-${checkSheetItem.id || checkSheetItem.uuid}`"
          :value="checkSheetItem.chkCycle"
          :disabled="!!checkSheetItem.id"
          placeholder="점검 주기"
          :optionItemsData="Object.keys(checkSheetCycleOptionNames)"
          :optionNames="checkSheetCycleOptionNames"
          @selectedOption="onSelectedOption(checkSheetItem, ...arguments)"
        />
        <template v-else>{{ checkSheetCycleOptionNames[checkSheetItem.chkCycle] }}</template>
      </span>
      <span>
        <input-box
          v-if="isEditMode"
          type="text"
          :id="`check-name-${checkSheetItem.id || checkSheetItem.uuid}`"
          :maxlength="10"
          v-model="checkSheetItem.name"
          placeholder="10자 이내 입력"
          autocomplete="off"
          :is-flex="true"
        />
        <template v-else>{{ checkSheetItem.name }}</template>
      </span>
      <span class="white-space-pre-line justify-start">
        <textarea-box
          v-if="isEditMode"
          type="text"
          :id="`check-method-${checkSheetItem.id || checkSheetItem.uuid}`"
          :maxlength="100"
          v-model="checkSheetItem.chkMethodDesc"
          placeholder="상세 점검방법 입력"
          autocomplete="off"
          :is-flex="true"
          :is-resizable="true"
        />
        <template v-else>{{ checkSheetItem.chkMethodDesc }}</template>
      </span>
      <span>
        <photo
          v-if="checkSheetItem.rscFiles.length > 0"
          :imgSrc="checkSheetItem.rscFiles[0].rscUrl"
          :isShowDeleteButton="isEditMode"
          @delete="onDeletePhoto(checkSheetItem)"
        />
        <file-basic
          v-else-if="isEditMode"
          accept=".jpg, .png"
          :id="`check-file-${checkSheetItem.id || checkSheetItem.uuid}`"
          class="text-button"
          @change="onSelectFile($event, checkSheetItem)"
        >
          추가
        </file-basic>
      </span>
      <span v-if="isEditMode">
        <button class="text-button text-button-gray" @click="$emit('copy', checkSheetItem)">복제</button>
        <button class="text-button text-button-gray" @click="$emit('delete', checkSheetItem)">삭제</button>
      </span>
    </li>
    <p v-if="!isEditMode" slot="no-list-description">
      <b>등록된 체크시트 항목이 없습니다.</b>
      <br />
      등록버튼을 클릭하여 체크시트 항목을 추가 하세요.
    </p>
  </list>
</template>
<script>
import Photo from '@/component/photoView/Photo';
import ListHeaderData from './ListHeaderData.json';
import List from '@/component/list/List';
import DropDownBox from '@/component/dropDown/DropDownBox';
import InputBox from '@/component/input/InputBox';
import checkCycle from '@/const/checkCycle';
import TextareaBox from '@/component/textarea/TextareaBox';

export default {
  name: 'CheckSheetItems',
  props: ['checkSheetItems', 'isEditMode', 'isPossibleHoverList', 'isDraggable'],
  data() {
    return {
      checkSheetHeaderList: ListHeaderData,
      checkSheetCycleOptionNames: checkCycle,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    onChangedList(changedList) {
      this.$emit('changedList', changedList);
    },
    onSelectedOption(checkSheetItem, selectedCycleOption) {
      checkSheetItem.chkCycle = selectedCycleOption;
    },
    onSelectFile(e, checkSheetItem) {
      this.$emit('selectFile', e, checkSheetItem);
    },
    onDeletePhoto(checkSheetItem) {
      this.$emit('deletePhoto', checkSheetItem);
    },
  },
  components: { TextareaBox, InputBox, DropDownBox, List, Photo },
};
</script>
<style scoped lang="scss">
@import 'CheckSheetItems';
</style>
